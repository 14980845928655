<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-title>DV<span style="font-weight: normal">iew</span></ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <div class="error-container">
                <div class="error-message-content">
                    <img src="/assets/feature/error/lightningcloud.svg"/>
                    <h2>Whoops!</h2>
                    <div v-if="message" class="text-body">
                        {{ message }}
                    </div>
                    <div v-else class="text-body"> <!-- default generic error message -->
                        Something went wrong.<br/>
                        Please try restarting the app.
                    </div>
                    <div class="text-body">
                        If the problem persists, please <br/>
                        check the status or write a message in <br/>
                        <a href="https://teams.microsoft.com/l/channel/19%3a697130ee085e42d0a433b17fa76c40dc%40thread.tacv2/Report%2520Incident?groupId=16bf648d-0bee-4f0b-894f-09f3a3ffbb64&tenantId=fdfed7bd-9f6a-44a1-b694-6e39c468c150">
                            the dedicated Teams channel
                        </a>
                    </div>
                </div>
                <ion-button @click="reloadApp">Restart App</ion-button>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        message: {
            type: String,
        },
    },
    setup() {
        const reloadApp = () => window.location.href = ''

        return {
            reloadApp,
        }
    },
})
</script>

<style scoped lang="scss">
ion-content {
    --background: #{$color-neutrals-t95};
}

.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 80px;
    height: 100%;

    .error-message-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 30px;
    }

    ion-button {
        min-width: 150px;
    }
}
</style>
